<template>
  <div class="vx-row">
    <div class="vx-col w-full lg:w-1/2 mb-base">
      <vx-card :title="$route.params.id ? `Edit Tax` : `Add New Tax`">
        <form-create :id="$route.params.id" @on-success="$router.push({name: 'taxes'})">
          <template v-slot:footer>
            <vs-button :to="{name:'taxes'}" type="border" color="warning">Back</vs-button>
          </template>
        </form-create>
      </vx-card>
    </div>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  }
}
</script>

<style>

</style>